.content-contacto{
    display: block;
    overflow: hidden;
    margin: 50px auto;

    h2{
        display: block;
        font-size: 20px;
        margin-bottom: 70px;
        position: relative;
        &:after{
            content: " ";
            display: block;
            position: absolute;
            top: 30px;
            width: 40px;
            height: 6px;
            background: $primary-color;
        }
    }
    
    .item_contacto{
        display: block;
        position: relative;
        overflow: hidden;

        p{
            font-family: $second-font;
            text-align: justify;
            margin-bottom: 20px;
            line-height: normal;
            span, a{
                color: $primary-color;
                font-family: $primary-font;
            }
            .hours{
                display: block;
            }
        }

        ul{
            display: block;
            font-family: $second-font;
            text-align: justify;
            margin-bottom: 20px;
            line-height: normal;
            li{
                margin-bottom: 10px;
            }
        }

        .box-contacto{
            width: 48%;
            display: block;
            float: left;
            position: relative;
            margin-right: 4%;
            margin-bottom: 4%;
            box-sizing: border-box;

            &:nth-child(2n){
                margin-right: 0;
            }

            @media (max-width: 680px){
                width: 100%;
            }
        }

        
    }
}




