$primary-color: #2e2e2e;
$second-color: #f5656e;
$second-color-opacity: rgba(245, 202, 220, .9);

$third-color: #fff;

$font-size-default: 11px;
$primary-font: 'Gotham-Black';
$second-font: 'GothamRounded-Medium';
$third-font: 'GothamRounded-Bold';
/////
 
