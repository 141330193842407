
@font-face {
    font-family: 'Gotham-Black';
    src: url('../fonts/Gotham-Black.eot') format('embedded-opentype'),
         url('../fonts/Gotham-Black.woff') format('woff'),
         url('../fonts/Gotham-Black.ttf') format('truetype'),
         url('../fonts/Gotham-Black.svg#Sri-TSCRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'GothamRounded-Medium';
    src: url('../fonts/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),  
         url('../fonts/GothamRounded-Medium.otf')  format('opentype'),
         url('../fonts/GothamRounded-Medium.woff') format('woff'), 
         url('../fonts/GothamRounded-Medium.ttf')  format('truetype'), 
         url('../fonts/GothamRounded-Medium.svg#GothamRounded-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'GothamRounded-Bold';
    src: url('../fonts/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'),  
         url('../fonts/GothamRounded-Bold.otf')  format('opentype'),
         url('../fonts/GothamRounded-Bold.woff') format('woff'), 
         url('../fonts/GothamRounded-Bold.ttf')  format('truetype'), 
         url('../fonts/GothamRounded-Bold.svg#GothamRounded-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  

  