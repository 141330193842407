
* {
   box-sizing: border-box;
}


body {
   font-family: $primary-font;
   color: $primary-color;
   width: 100%;
   background: $third-color;
   font-size: $font-size-default;
   letter-spacing: 1px;
}

a{
   text-decoration: none;
   transition: all .4s;
   -webkit-transition: all .4s;
   -moz-transition: all .4s;
   -ms-transition: all .4s;
   -o-transition: all .4s;
}



h1, h2, h3, h4{
   text-transform: uppercase;   
}

.container{
   display: block;
   margin: 0 auto;
   position: relative;
   overflow: hidden;
   width: 90%;
   max-width: 1200px;

   @media (max-width: 900px){
      width: 95%;
   }
   
}

.content-middle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
    width: 100%;
}


.img-fluid{
   display: block;
   width: 100%;
   height: auto;
}








