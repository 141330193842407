footer{
    width: 100%;
    height: auto;
    padding: 40px 0;
    overflow: hidden;
    background: $third-color;
    position: relative;

    &:before{
        content: "";
        width: 100%;
        max-width: 400px;
        background: $primary-color;
        position: absolute;
        top: 0;
        left: 0;
        height: 6px;
    }

    .content-footer{
        display: block;
        overflow: hidden;
        height: auto;

        
        .item_footer{
            width: 18.4%;
            display: block;
            float: left;
            margin-right: 2%;
            box-sizing: border-box;
            margin-bottom: 20px;
            text-transform: initial;

            &:nth-child(5n){
                margin-right: 0;
            }

            @media (max-width: 1110px){
                width: 49%;

                &:nth-child(2n){
                    margin-right: 0;
                    
                }
            }

            @media (max-width: 680px){
                width: 100%;

                &:nth-child(2n){
                    margin-right: 0;
                    
                }
            }

            img{
                max-width: 200px;
            }

            ul{
                width: 100%;
                height: auto;
                overflow: hidden;

                li{
                    width: 100%;
                    height: 25px;
                    
                    a{
                        text-transform: inherit;
                        color: $primary-color;
                        width: 100%;
                        background: $third-color;
                        display: block;
                        height: 25px;
                        line-height: 23px;
                        padding-left: 10px;
                        font-size: 11px;

                        transition: all .4s;
                        -webkit-transition: all .4s;

                        &:hover{
                            color:$third-color;
                            background:$primary-color;
                        }

                        span{
                            background: $primary-color;
                            color: $third-color;
                            width: 25px;
                            height: 25px;
                            text-align: center;
                            border-radius: 100%;
                            margin-right: 10px;
                            display: block;
                            float: left;
                            line-height: 25px;
                            font-size: 15px;
                        }
                    }
                } 
            }

            ul.contact{
                li{
                    height: 30px;
                    a{
                        font-size: 9px;

                        &:hover{
                            background: transparent;
                            text-decoration: underline;
                            color: $primary-color;
                        }
                    }
                }
            }

            .logo-footer{
                width: 50%;
                margin: 0 auto;
                display: block;
            }
        }

        .content-logo{
            width: 18.4%;
            @media (max-width: 1110px){
                width: 100%;
                margin-top: 30px;

                img{
                    margin: 0 auto;
                    max-width: 200px;
                }
            }

            @media (max-width: 680px){
                width: 100%;
                margin-top: 30px;

                img{
                    max-width: 200px;
                    float: left;
                }
            }
        }

    }
}


.carro-fixed{
    display: none;
    background: $third-color;
    border-top: 1px solid $third-color - 20; 
    height: 60px;
    color: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    line-height: 50px;
    transition: all .6s;
    -webkit-transition: all .6s;
    -o-transition: all .6s;
    -moz-transition: all .6s;
    -ms-transition: all .6s;
    z-index: 9;
    text-transform: uppercase;
    @media (max-width: 680px){
        display: block;
    }

    a{
        color: $primary-color;
        width: 100%;
        display: block;
        height: 60px;
        line-height: 60px;
        position: relative;
        span{
            text-align: center;
            display: block;
            width: 100%;
            font-size: 14px;
        }

        .bag{
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            height: 60px;

            .alert-cicle{
                display: block;
                width: 13px;
                height: 13px;
                background: $second-color;
                border-radius: 100%;
                position: absolute;
                right: 10px;
                top: 10px;
                text-align: center;
                line-height: 12px;

                span{
                    color: $third-color;
                    font-size: 9px;
                }
            }
            .down-bag{
                color: $primary-color;
                line-height: 60px;
                font-size: 40px;
                cursor: pointer;
                text-align: center;
            }
        }
    }
}