.content-sale{
    display: block;
    width: 100%;
    margin-bottom: 70px;
    overflow: hidden;
    margin-top: 70px;

    .item_sale{
        width: 49%;
        height: auto;
        overflow: hidden;
        display: block;
        float: left;
        margin-right: 2%;
        margin-bottom: 4%;
        position: relative;

        &:nth-child(2n){
            margin-right: 0;
        }

        @media (max-width: 768px){
            width: 100%;
        }
    }

    .box-number, .box-legal{
        display: block;
        float: left;
    }
    .box-number{
        background: $second-color;
        width: 15%;
        height: 100px;
        text-align: center;
        margin-right: 2%;
        span{
            line-height: 100px;
            font-size: 40px;
        }
    }
    .box-legal{
        float: left;
        width: 83%;

        h3{
            margin-bottom: 30px;
            font-size: 15px;
            display: block;
            &:after{
                content: " ";
                display: block;
                position: absolute;
                top: 25px;
                width: 30px;
                height: 6px;
                background: $primary-color;
            }
        }
        ul{
            display: block;
            overflow: hidden;
            font-family: $second-font;

            li{
                display: block;
                margin-bottom: 3px;
                line-height: normal;

                @media (max-width: 680px){
                    margin-bottom: 7px;
                }

                a{
                    font-family: $primary-font;
                    text-transform: uppercase;
                    color: $primary-color;

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}